import { createRouter, createWebHistory } from 'vue-router'
import store from './store'

import Terms from './pages/Terms.vue';
import Upload from './pages/Upload.vue';
//import Process from './pages/Process.vue';
import Success from './pages/Success.vue';
import Failure from './pages/Failure.vue';
import NotFound from './pages/NotFound.vue';

const routes = [
    //{
    //    path: '/',
    //    redirect: '/sign'
    //},
    {
        path: '/sign',
        component: Terms,
        meta: { requiresAuth: true }
    },
    {
        path: '/sign/upload',
        component: Upload,
        meta: { requiresAuth: true }
    },
    //{
    //    path: '/sign/upload/process',
    //    component: Process,
    //    meta: { requiresAuth: true }
    //},
    {
        path: '/sign/upload/success',
        component: Success,
        meta: { requiresAuth: true }
    },
    {
        path: '/sign/upload/fail',
        component: Failure,
        meta: { requiresAuth: true }
    },
    {
        path: '/error',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) =>
{    
    if ( to.meta.requiresAuth && !store.getters.isAuthenticated )
    {
        next({ path: '/error' });
    }
    else
    {
        next();
    }
});

router.afterEach((to, from) =>
{
    const toPath = to.path.split('/');
    const fromPath = from.path.split('/');

    if ( toPath[1] === fromPath[1] )
    {
        to.meta.transition = toPath.length < fromPath.length ? 'slide-left' : 'slide-right';
    }
    else
    {
        to.meta.transition = 'fade';
    }
})

export default router