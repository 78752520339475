<template>
    <div class="wsuccess">
        <Header :title="$t('head.invite')" />
        
        <h1 style="margin-top:16px;">{{ $t('success.title') }}</h1>
        <p style="margin-top:16px;" v-html="$t('success.subtitle')"></p>
        <div class="contract">
            <div style="display: flex; justify-content: space-between;">
                <div class="type">{{ $t('category.' + contractInfo.category) }}</div>
                <div class="time">{{ $utils.formatTimestamp(contractInfo.complete_time) }}</div>
            </div>
            <div class="users">{{ participants }}</div>
        </div>
        <button class="share">
            <img src="@/assets/images/share.svg" style="padding-right: 8px;" />
            {{ $t('success.share') }}
        </button>
        <button class="combtn hover">{{ $t('success.close') }}</button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from '@/components/Header.vue'

export default
{
    name: 'sign-success',
    data()
    {
        return {
            
        };
    },
    computed:
    {
        ...mapGetters({
            paramData: 'getParamData',
            contractInfo: 'getContractInfo'
        }),
        participants()
        {
            let participants = [];

            participants.push(this.contractInfo.participant.me.name);
            participants.push(this.contractInfo.participant.from.name);

            for ( var i = 0 ; i < this.contractInfo.participant.to.length ; i++ )
            {
                participants.push(this.contractInfo.participant.to[i].name);
            }

            return participants.join(", ");
        }
    },
    methods:
    {
        ...mapActions(["actionContractInfo"]),

        async requestContractInfo()
        {
            const res = await this.$comm.RequestGET(this.$i18n.locale, '/v1/contract/info', {
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                email: this.paramData.email
            });
            if ( res.code == 'SUCCESS' )
            {
                this.actionContractInfo(res.data);
            }
            else
            {
                alert(res.message);
            }
        },
    },
    created()
    {
        this.requestContractInfo();
    },
    components:
    {
        Header
    }
}
</script>

<style scoped>
.wsuccess { position: absolute; top: 0; left:0; right:0; height: 100%; }
.wsuccess .contract { border: 0.5px solid #E3E5E8; border-radius: 8px; margin-top: 32px; padding: 16px; }
.wsuccess .contract .type { border-radius: 4px; background-color: #129EBD; padding: 4px 8px; font-size: 14px; color: #FFF; }
.wsuccess .contract .time { font-size: 12px; font-weight: 600; }
.wsuccess .contract .users { font-size: 14px; font-weight: 500; margin-top: 16px; }

.wsuccess .share { width: 100%; margin-top: 16px; display: flex; align-items: center; justify-content: center; }
.wsuccess .combtn { position:absolute; left:0; right:0; bottom:16px; }

</style>