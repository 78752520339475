<template>
    <div class="wNotFound">
        <h1>Incorrect Access!!</h1>
    </div>
</template>

<script>
export default
{
    name: 'sign-notfound'
}
</script>

<style scoped>
.wNotFound { position: absolute; top: 0; left:0; right:0; height: 100%; }
.wNotFound h1 { margin: 48px auto; text-align: center; }

</style>