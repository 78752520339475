import { createApp } from 'vue'
import App from './App.vue'

import mitt from 'mitt'
import Vue3Signature from "vue3-signature"
import Vue3Progress from "vue3-progress"
import Chat from 'vue3-beautiful-chat'

import router from './router'
import store from './store'
import comm from './comm'
import utils from './utils'
import i18n from './i18n'

import './assets/style.css'

const emitter = mitt();
const app = createApp(App)

app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$comm = comm;
app.config.globalProperties.$utils = utils;

app.use(store)
app.use(router)
app.use(i18n)
app.use(Vue3Signature)
app.use(Vue3Progress)
app.use(Chat)
app.mount('#app')