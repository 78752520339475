import crypto from 'crypto-js';
import axios from 'axios';

const comm =
{
    GetNonce: function()
    {
        return new Date().getTime();
    },
    GenerateHMAC: function(data, key)
    {
        const hmac = crypto.HmacSHA256(data, key);
        return hmac.toString(crypto.enc.Base64);
    },
    RequestGET: function(lang, trid, body)
    {
        return new Promise((resolve, reject) =>
        {
            const queryStr = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

            const url = process.env.VUE_APP_API_BASE_URL;
            var uri = trid 
            if ( queryStr.length > 0 ) uri = uri + '?' + queryStr;

            const nonce = this.GetNonce();
            const enddata = nonce + '\n' + process.env.VUE_APP_API_KEY + '\n' + uri;
            const signature = this.GenerateHMAC(enddata, process.env.VUE_APP_API_SECRET);
            
            var options = {
                method: 'get',
                url: url + uri,
                headers: {
                    'Content-Type': 'application/json',
                    'X-MEDIASIGN-LANG': lang,
                    'X-MEDIASIGN-NONCE': nonce,
                    'X-MEDIASIGN-ACCESS': process.env.VUE_APP_API_KEY,
                    'X-MEDIASIGN-SIGNATURE': signature
                }
            };

            axios(options).then((res) => { resolve(res.data) }).catch((err) => { reject(err) });
        });
    },
    RequestPOST: function(lang, trid, body)
    {
        return new Promise((resolve, reject) =>
        {
            const postStr = JSON.stringify(body);

            const url = process.env.VUE_APP_API_BASE_URL;
            const uri = trid;

            const nonce = this.GetNonce();
            const enddata = nonce + '\n' + process.env.VUE_APP_API_KEY+ '\n' + uri;
            const signature = this.GenerateHMAC(enddata, process.env.VUE_APP_API_SECRET);

            var options = {
                    method: 'post',
                    url: url + uri,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-MEDIASIGN-LANG': lang,
                        'X-MEDIASIGN-NONCE': nonce,
                        'X-MEDIASIGN-ACCESS': process.env.VUE_APP_API_KEY,
                        'X-MEDIASIGN-SIGNATURE': signature
                    },
                    data: postStr
            };

            axios(options).then((res) => { resolve(res.data) }).catch((err) => { reject(err) });
        });
    },
    RequestPOSTwithFile: function(lang, trid, body, blob, filename)
    {
        return new Promise((resolve, reject) =>
        {
            const formData = new FormData();

            formData.append('jsonBody', JSON.stringify(body));
            formData.append('file', blob, filename);

            const url = process.env.VUE_APP_API_BASE_URL;
            const uri = trid;

            const nonce = this.GetNonce();
            const enddata = nonce + '\n' + process.env.VUE_APP_API_KEY+ '\n' + uri;
            const signature = this.GenerateHMAC(enddata, process.env.VUE_APP_API_SECRET);

            var options = {
                    method: 'post',
                    url: url + uri,
                    headers: {
                        //'Content-Type': 'application/json',
                        'X-MEDIASIGN-LANG': lang,
                        'X-MEDIASIGN-NONCE': nonce,
                        'X-MEDIASIGN-ACCESS': process.env.VUE_APP_API_KEY,
                        'X-MEDIASIGN-SIGNATURE': signature
                    },
                    data: formData
            };

            axios(options).then((res) => { resolve(res.data) }).catch((err) => { reject(err) });
        });
    },
    RequestFILE: function(lang, trid, body)
    {
        return new Promise((resolve, reject) =>
        {
            const queryStr = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');

            const url = process.env.VUE_APP_API_BASE_URL;
            var uri = trid 
            if ( queryStr.length > 0 ) uri = uri + '?' + queryStr;

            const nonce = this.GetNonce();
            const enddata = nonce + '\n' + process.env.VUE_APP_API_KEY + '\n' + uri;
            const signature = this.GenerateHMAC(enddata, process.env.VUE_APP_API_SECRET);
            
            var options = {
                method: 'get',
                url: url + uri,
                responseType: 'blob',
                headers: {
                    //'Content-Type': 'application/json',
                    'X-MEDIASIGN-LANG': lang,
                    'X-MEDIASIGN-NONCE': nonce,
                    'X-MEDIASIGN-ACCESS': process.env.VUE_APP_API_KEY,
                    'X-MEDIASIGN-SIGNATURE': signature
                }
            };

            axios(options).then((res) => { resolve(res.data) }).catch((err) => { reject(err) });
        });
    },
};

export default comm
