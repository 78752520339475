<template>
    <div class="wheader">
        <header>
            <div class="prev">
                <slot name="prev"></slot>
            </div>
            <div class="title">
                <span>{{ title }}</span>
                <div class="progress" v-if="progress > 0">
                    <li v-for="n in 3" :key="n" :class="[{past: n < progress_curr}, {curr: n == progress_curr}]"></li>
                </div>
            </div>
            <div class="after">
                <slot name="after"></slot>
            </div>
        </header>
        <div style="height:50px"></div>
    </div>
</template>

<script>
export default
{
    name: 'app-header',
    data()
    {
        return {
            
        };
    },
    props:
    {
        title: { type: String },
        progress: { type: Number, default: 0 },
        progress_curr: { type: Number, default: 0 },
    }
}
</script>

<style scoped>
header { display: flex; justify-content: space-between; position: absolute; top: 0; left: 0; right:0; height: 40px; background-color: white; text-align: center; }
header .title { display: flex; flex-direction: column; justify-content: center; }
header .title span { font-size: 14px; font-weight: 600; color: #313336; }
header .title .progress { display: flex; justify-content: center; }
header .title .progress li { list-style: none; width: 4px; height: 4px; border-radius: 4px; background-color: #E3E5E8; margin: 0 2px; margin-top: 3px; }
header .title .progress li.past { background-color: #7E8185; }
header .title .progress li.curr { background-color: #1255BA; width: 16px; }
header .prev { display: flex; align-items: center; width: 60px; }
header .after { display: flex; align-items: center; justify-content: flex-end; width: 60px; }
</style>