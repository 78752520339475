<template>
    <div class="container">
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition">
                <component :is="Component" />
            </transition>
        </router-view>

        <div class="chat" v-if="this.$route.path != '/error'">
            <beautiful-chat
                :participants="participants"
                :messageList="chatting.messageList"
                :newMessagesCount="chatting.newMessagesCount"
                :isOpen="chatting.isChatOpen"
                :colors="chatting.colors"
                :close="closeChat"
                :open="openChat"
                :onMessageWasSent="onMessageWasSent">

                <template v-slot:header>
                    Group Chatting <span style="font-size:14px; opacity:0.5;">{{ participants.length }} Persons</span>
                </template>

                <template v-slot:text-message-body="{ message }">
                    <div class="chatmsg">
                        <div class="name" :class="{'me': message.author == 'me'}">{{ message.data.name }}</div>
                        <div class="text" :class="{'me': message.author == 'me'}">{{ message.data.text }}</div>
                        <div class="time" :class="{'me': message.author == 'me'}">{{ $utils.printTime2(message.data.time) }}</div>
                    </div>
                </template>

            </beautiful-chat>
        </div>

        <div class="pcircle" v-if="is_loading">
            <loading :active="true" :is-full-page="false" color="#1255BA"></loading>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default
{
    name: 'app',
    data()
    {
        return {
            chatting: {
                messageList: [
                    { type: 'text', author: 'support', data: { text: `Say yes!Say yes!Say yes!Say yes!Say yes!`, time: 1725519930505, name: '1111' } },
                    { type: 'text', author: 'me', data: { text: `No.`, time: 1725519930505, name: '2222' } },
                    { type: 'text', author: 'support', data: { text: `Say yes!`, time: 1725519930505, name: '1111' } },
                    { type: 'text', author: 'me', data: { text: `No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.No.`, time: 1725519930505, name: '2222' } },
                    { type: 'text', author: 'support', data: { text: `Say yes!`, time: 1725519930505, name: '1111' } },
                    { type: 'text', author: 'me', data: { text: `No.`, time: 1725519930505, name: '2222' } },
                    { type: 'text', author: 'support', data: { text: `Say yes!`, time: 1725519930505, name: '1111' } },
                    { type: 'text', author: 'me', data: { text: `No.`, time: 1725519930505, name: '2222' } },
                ],
                newMessagesCount: 2,
                isChatOpen: false,
                colors: {
                    header: { bg: '#4e8cff', text: '#ffffff' },
                    launcher: { bg: '#4e8cff' },
                    messageList: { bg: '#ffffff' },
                    sentMessage: { bg: 'transparent'/*'#4e8cff'*/, text: '#ffffff' },
                    receivedMessage: { bg: 'transparent'/*'#eaeaea'*/, text: '#222222' },
                    userInput: { bg: '#f4f7f9', text: '#565867' }
                }
            },
            is_loading: false
        };
    },
    computed:
    {
        ...mapGetters({
            paramData: 'getParamData',
            contractInfo: 'getContractInfo'
        }),
        participants()
        {
            if ( this.contractInfo == null ) return [];
            if ( this.contractInfo.participant == null ) return [];

            let participants = [];

            participants.push({
                id: this.contractInfo.participant.me.email,
                name: this.contractInfo.participant.me.name,
            });

            participants.push({
                id: this.contractInfo.participant.from.email,
                name: this.contractInfo.participant.from.name,
            });

            for ( var i = 0 ; i < this.contractInfo.participant.to.length ; i++ )
            {
                participants.push({
                    id: this.contractInfo.participant.to[i].email,
                    name: this.contractInfo.participant.to[i].name,
                }); 
            }

            return participants;
        }
    },
    methods:
    {
        ...mapActions(["actionParamData", "actionContractInfo"]),

        async requestContractInfo()
        {
            const res = await this.$comm.RequestGET(this.$i18n.locale, '/v1/contract/info', {
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                email: this.paramData.email,
                usefile: 'Y'
            });
            if ( res.code == 'SUCCESS' )
            {
                this.actionContractInfo(res.data);
                if ( this.contractInfo.status == 'V' )
                {
                    this.$router.push('/sign/upload/success');
                }
                else if ( this.contractInfo.status == 'Z' )
                {
                    this.$router.push('/sign/upload/fail');
                }
                else 
                {
                    this.$router.push('/sign');
                }
            }
            else
            {
                alert(res.message);
            }
        },
        procesQueryString(param)
        {
            try
            {
                var plane = window.atob(param.slice(1, -3));
                var paramArray = plane.split(':');

                if ( paramArray.length == 4 )
                {
                    var paramData = {
                        lang: paramArray[0],
                        contract_id: Number(paramArray[1]),
                        userid: Number(paramArray[2]),
                        email: paramArray[3],
                    };

                    this.$i18n.locale = paramData.lang;
                    this.actionParamData(paramData);
                    this.requestContractInfo(true);
                }
                else
                {
                    this.$router.go('/error');
                }
            }
            catch (e)
            {
                this.$router.go('/error');
            }
        },        

        sendMessage(text)
        {
            if ( text.length > 0 )
            {
                this.chatting.newMessagesCount = this.chatting.isChatOpen ? this.chatting.newMessagesCount : this.chatting.newMessagesCount + 1;
                this.onMessageWasSent({ author: 'support', type: 'text', data: { text } });
            }
        },
        onMessageWasSent(message)
        {
            console.log(message);
            this.chatting.messageList = [ ...this.chatting.messageList, message ];
        },
        openChat()
        {
            this.chatting.isChatOpen = true
            this.chatting.newMessagesCount = 0
        },
        closeChat()
        {
            this.chatting.isChatOpen = false
        },
        onShowLoading()
        {
            this.is_loading = true;
        },
        onHideLoading()
        {
            this.is_loading = false;
        }
    },
    mounted()
    {
        this.$emitter.on('show-loading', this.onShowLoading);
        this.$emitter.on('hide-loading', this.onHideLoading);

    },
    beforeUnmount()
    {
        this.$emitter.off('show-loading', this.onShowLoading);
        this.$emitter.off('hide-loading', this.onHideLoading);
    },
    created()
    {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const q = urlParams.get('q');

        if ( q != null && q.length > 0 )
        {
            this.procesQueryString(q);
        }
    },
    components:
    {
        Loading
    }
}
</script>

<style scoped>
.chat :deep(.sc-launcher) { position: absolute; right: 0; bottom: 80px; }
.chat :deep(.sc-launcher.opened:before) { box-shadow: none; }
.chat :deep(.sc-open-icon) { position: absolute !important; right: 0 !important; bottom: 0 !important; }
.chat :deep(.sc-closed-icon) { position: absolute !important; right: 0 !important; bottom: 0 !important; }
.chat :deep(.sc-chat-window) { position: absolute !important; right: 16px !important; bottom: 145px !important; border: 0.5px solid #E3E5E8; }
.chat :deep(.sc-message) { width: 90% !important; }
.chat :deep(.sc-message--text) { padding: 0 !important; }

.chatmsg { position: relative; }
.chatmsg .name { font-size: 12px; opacity: 0.5; text-align: left; }
.chatmsg .name.me { text-align: right; }
.chatmsg .text { background-color: #eaeaea; color: #222222; border-radius: 8px; padding: 8px; text-align: left; }
.chatmsg .text.me { background-color: #4e8cff; color: #FFF; text-align: right; }
.chatmsg .time { font-size: 12px; opacity: 0.5; text-align: right; }
.chatmsg .time.me { text-align: left; }

.pcircle { position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center; background-color: rgba(255, 255, 255, 0.5); }


/* 기본 페이드 효과 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* 슬라이드로 덮는 애니메이션 */
.none-enter-active, .none-leave-active { display: none; }
.none-enter-from { display: none; }
.none-leave-to { display: none; }

/* slide right */
.slide-right-enter-active, .slide-right-leave-active { transition: transform 0.2s ease; }
.slide-right-enter-from { transform: translateX(100%); }
.slide-right-leave-to { transform: translateX(-100%); }

/* slide left */
.slide-left-enter-active, .slide-left-leave-active { transition: transform 0.2s ease; }
.slide-left-enter-from { transform: translateX(-100%); }
.slide-left-leave-to { transform: translateX(100%); }
</style>