<template>
    <div class="wfailure">
        <Header :title="$t('head.invite')" />
        
        <h1 style="margin-top:16px;">{{ $t('failure.title') }}</h1>
        <p style="margin-top:16px;">
            {{ $t('failure.subtitle') }}
        </p>
        
        <ul class="users">
            <li v-for="(item, index) in participants" :key="index">
                <i class="user" :style="getUserColor(index)"></i>
                <span>{{ item.name }}</span>
                <div :class="item.status">{{ $t('status.' + item.status) }}</div>
            </li>
        </ul>
        
        <button class="combtn">{{ $t('failure.close') }}</button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from '@/components/Header.vue'

export default
{
    name: 'sign-failure',
    data()
    {
        return {
            
        };
    },
    computed:
    {
        ...mapGetters({
            paramData: 'getParamData',
            contractInfo: 'getContractInfo',
        }),
        participants()
        {
            let participants = [];

            participants.push(this.contractInfo.participant.me);
            participants.push(this.contractInfo.participant.from);

            for ( var i = 0 ; i < this.contractInfo.participant.to.length ; i++ )
            {
                participants.push(this.contractInfo.participant.to[i]);
            }

            return participants;
        }
    },
    methods:
    {
        ...mapActions(["actionContractInfo"]),

        getUserColor(index)
        {
            return 'background-color: ' + this.$utils.getParticipantIconColor(index);
        },
        async requestContractInfo()
        {
            const res = await this.$comm.RequestGET(this.$i18n.locale, '/v1/contract/info', {
                userid: this.paramData.userid,
                contract_id: this.paramData.contract_id,
                email: this.paramData.email
            });
            if ( res.code == 'SUCCESS' )
            {
                this.actionContractInfo(res.data);
            }
            else
            {
                alert(res.message);
            }
        },
    },
    created()
    {
        this.requestContractInfo();
    },
    components:
    {
        Header
    }
}
</script>

<style scoped>
.wfailure { position: absolute; top: 0; left:0; right:0; height: 100%; }
.wfailure .users { display: flex; justify-content: space-around; list-style: none; margin-top: 80px; }
.wfailure .users li { display: flex; flex-direction: column; align-items: center; }
.wfailure .users li span { color: #0C0C0D; font-size: 14px; margin-top: 8px; margin-bottom: 6px; }
.wfailure .users li .waiting { background: url(@/assets/images/signature_waiting.svg) no-repeat left; background-size: contain; color: #7E8185; font-size: 14px; padding-left: 24px; }
.wfailure .users li .complete { background: url(@/assets/images/signature_complete.svg) no-repeat left; background-size: contain; color: #1255BA; font-size: 14px; padding-left: 24px; }
.wfailure .users li .reject { background: url(@/assets/images/signature_reject.svg) no-repeat left; background-size: contain; color: #C41919; font-size: 14px; padding-left: 24px; }
.wfailure .combtn { position:absolute; left:0; right:0; bottom:16px; background-color: #C41919; color: #FFF; }

</style>